.light-theme {
  --datepicker-border-color: var(--chakra-colors-gray-200);
  --selected: var(--chakra-colors-blue-500);
  --time-container-border-color: var(--chakra-colors-gray-200);
  --hover: var(--chakra-colors-gray-200);
  --header-background: var(--chakra-colors-gray-100);
  --today-button: var(--chakra-colors-gray-100);
  --monthBackground: var(--chakra-colors-white);
  --text: var(--chakra-colors-black);
  --white-text: var(--chakra-colors-white);
}

.dark-theme {
  --datepicker-border-color: var(--chakra-colors-gray-600);
  --selected: var(--chakra-colors-blue-300);
  --time-container-border-color: var(--chakra-colors-gray-600);
  --hover: var(--chakra-colors-gray-600);
  --header-background: var(--chakra-colors-gray-800);
  --today-button: var(--chakra-colors-gray-800);
  --monthBackground: var(--chakra-colors-gray-700);
  --text: var(--chakra-colors-gray-200);
  --white-text: var(--chakra-colors-white);
}

.react-datepicker {
  font-family: unset;
  font-size: 0.9rem;
  border-color: var(--datepicker-border-color);
}

.react-datepicker__header {
  background-color: var(--header-background);
}

.react-datepicker__header,
.react-datepicker__time-container {
  border-color: var(--time-container-border-color);
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: inherit;
  font-weight: 600;
  color: var(--text);
}

.react-datepicker__month {
  background-color: var(--monthBackground);
  margin: 0;
  padding: 0.4rem;
}

.react-datepicker__day {
  color: var(--text);
}

.react-datepicker__day:hover {
  background: var(--hover);
  color: var(--text);
}

.react-datepicker__day-name {
  color: var(--text);
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  color: var(--white-text);
}

.react-datepicker__today-button {
  background: var(--today-button);
  border-top: 1px solid var(--datepicker-border-color);
  color: var(--text);
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
  border-bottom-color: var(--header-background);
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
  border-bottom-color: var(--datepicker-border-color);
}
